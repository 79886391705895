import * as React from 'react';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const ITEM_HEIGHT = 48;

export type ItemSelecterProps = {
    items: any[];
    current: any;
    setCurrent: any;
};

export const ItemSelecter: React.FC<ItemSelecterProps> = ({
    items,
    current,
    setCurrent,
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => {
        if (event.currentTarget && event.currentTarget.value !== undefined) {
            const item = items[event.currentTarget.value];
            setCurrent(item);
        }
        setAnchorEl(null);
    };

    return (
        <Box component="span">
            {current && current.name}
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <ArrowDropDownIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '60ch',
                    },
                }}
            >
                {items.map((item, i) => (
                    <MenuItem
                        key={i}
                        selected={i === 0}
                        value={i}
                        onClick={handleClose}
                    >
                        {item.name}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};
