import { createMuiTheme } from '@material-ui/core/styles';

export const defaultTheme = createMuiTheme({
    palette: {
        primary: {
            light: '#7986cb',
            main: '#3f51b5',
            dark: '#303f9f',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff4081',
            main: '#f50057',
            dark: '#c51162',
            contrastText: '#fff',
        },
    },
});
