import * as React from 'react';
import { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core';
import { API, InitializeFirebase } from './api';
import {
    SignUp,
    PasswordReset,
    AuthAction,
    UserProvider,
    Autnenticate,
} from './components/user';
import { CacheContext, ConfigContext } from './components/Context';
import { Main } from './components/Main';
import { configInit } from './components/Config';
import { defaultTheme } from './theme/default';
import { dockTheme } from './theme/dock';

InitializeFirebase();
const config = configInit();

export const App: React.FC = () => {
    useEffect(() => {
        document.title = config.documentTitle;
    }, []);

    if (!config) {
        return <div>fatal error</div>;
    }

    const theme = config.theme == 'dock' ? dockTheme : defaultTheme;

    return (
        <MuiThemeProvider theme={theme}>
            <ContextProvider>
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/signup">
                            <SignUp />
                        </Route>
                        <Route exact path="/password">
                            <PasswordReset />
                        </Route>
                        <Route exact path="/auth/action">
                            <AuthAction />
                        </Route>
                        <Route path="/">
                            <Autnenticate>
                                <Main />
                            </Autnenticate>
                        </Route>
                    </Switch>
                </BrowserRouter>
            </ContextProvider>
        </MuiThemeProvider>
    );
};

const ContextProvider: React.FC = ({ children }) => {
    const [cache, setCache] = useState({});

    return (
        <ConfigContext.Provider value={config}>
            <UserProvider>
                <CacheContext.Provider
                    value={{ value: cache, setValue: setCache }}
                >
                    {children}
                </CacheContext.Provider>
            </UserProvider>
        </ConfigContext.Provider>
    );
};

export const App2 = () => {
    const [id, setId] = useState(null);
    const [data, setData] = useState(null);

    useEffect(() => {
        (async () => {
            const res = await API.hello(id);
            setData(res);
        })();
    }, [id]);

    const submit = () => {
        setId('hello');
    };

    return (
        <div>
            <p>{data ? data['name'] : 'loading...'}</p>
            <button onClick={submit}>submit</button>
        </div>
    );
};
