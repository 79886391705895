import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';

import * as api from '../api';
import * as lib from '../lib';
import { Title } from './Title';
import { ConfigContext } from './Context';
import { UserContext } from './user';

const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    textLink: {
        marginBottom: '1px',
    },
}));

type PromotionsTableProps = {};

export const PromotionsTable: React.FC<PromotionsTableProps> = () => {
    const classes = useStyles();
    const history = useHistory();
    const [promotions, setPromotions] = useState(
        [] as firebase.firestore.DocumentSnapshot[]
    );
    const config = useContext(ConfigContext);
    const userStatus = useContext(UserContext);
    const store = new api.Store(config.environment);

    useEffect(() => {
        (async () => {
            const data = await store.promotions(
                userStatus.user.email,
                config.serviceId
            );
            setPromotions(data);
        })();
    }, [userStatus]);

    if (!promotions || promotions.length == 0) {
        return <div />;
    }

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    color="inherit"
                    href="/"
                    onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        history.push('/');
                    }}
                >
                    Dashbord
                </Link>
            </Breadcrumbs>

            <Title>Promotions</Title>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>URL</TableCell>
                        <TableCell>Registered at</TableCell>
                        {/* <TableCell align="right">Sale Amount</TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {promotions.map((row, i) => (
                        <TableRow key={i}>
                            <TableCell>
                                <Link
                                    color="primary"
                                    href={`/promotions/${row.id}`}
                                    onClick={(e: React.MouseEvent) => {
                                        e.preventDefault();
                                        history.push(`/promotions/${row.id}`);
                                    }}
                                >
                                    {row.data().displayName}
                                </Link>
                            </TableCell>
                            <TableCell>
                                <Link
                                    color="primary"
                                    href={row.data().siteUri}
                                    target="_blank"
                                >
                                    {row.data().siteUri}
                                </Link>
                            </TableCell>
                            <TableCell>
                                {lib.DateFormat(row.data().createdAt.toDate())}
                            </TableCell>
                            {/* <TableCell align="right">312.4</TableCell> */}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {/* <div className={classes.seeMore}>
                <Link color="primary" href="#">
                    See more orders
                </Link>
            </div> */}
        </>
    );
};

type PromotionDetailProps = {};

export const PromotionDetail: React.FC<PromotionDetailProps> = () => {
    const { pid } = useParams<Record<string, string | undefined>>();
    const classes = useStyles();
    const history = useHistory();
    const [promotion, setPromotion] = useState(
        null as firebase.firestore.DocumentSnapshot
    );
    const [approvals, setApprovals] = useState(
        [] as firebase.firestore.DocumentSnapshot[]
    );
    const [loading, setLoading] = useState(true);
    const config = useContext(ConfigContext);
    const store = new api.Store(config.environment);

    useEffect(() => {
        (async () => {
            const data = await store.promotion(pid);
            setPromotion(data);
            const a = await store.approvalByPromotionId(pid);
            setApprovals(a);
            setLoading(false);
        })();
    }, [pid]);

    if (loading) {
        return <div />;
    }
    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    color="inherit"
                    href="/"
                    onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        history.push('/');
                    }}
                >
                    Dashbord
                </Link>
                <Link
                    color="inherit"
                    href="/promotions"
                    onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        history.push('/promotions');
                    }}
                >
                    Promotions
                </Link>
            </Breadcrumbs>

            <Title>{promotion.data().displayName}</Title>
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell variant="head">URL</TableCell>
                        <TableCell>
                            <Link
                                color="primary"
                                href={promotion.data().siteUri}
                                target="_blank"
                            >
                                {promotion.data().siteUri}
                            </Link>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Description</TableCell>
                        <TableCell>{promotion.data().description}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Registered at</TableCell>
                        <TableCell>
                            {lib.DateFormat(
                                promotion.data().createdAt.toDate()
                            )}
                        </TableCell>
                    </TableRow>
                    {/* <LinkRow pid={promotion.id} /> */}
                    <TableRow>
                        <TableCell variant="head">Conversion link</TableCell>
                        <TableCell>
                            <LinkRow pid={promotion.id} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">
                            Conversion link(for testing)
                        </TableCell>
                        <TableCell>
                            <LinkRow pid={promotion.id} test={true} />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <br />

            <ApprovalList approvals={approvals} />
            <br />

            <MemberList promotion={promotion.data() as api.Promotion} />
        </>
    );
};

type ApprovalListProps = {
    approvals: firebase.firestore.DocumentSnapshot<
        firebase.firestore.DocumentData
    >[];
};
const ApprovalList: React.FC<ApprovalListProps> = ({ approvals }) => {
    const classes = useStyles();

    return (
        <>
            <Title>Sites</Title>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>URL</TableCell>
                        {/* <TableCell>Text Link</TableCell> */}
                        <TableCell>Status</TableCell>
                        <TableCell>Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {approvals.map((row, i) => (
                        <ApprovalTableRow key={i} approval={row} />
                    ))}
                </TableBody>
            </Table>
        </>
    );
};

type ApprovalTableRowProps = {
    approval: firebase.firestore.DocumentSnapshot<
        firebase.firestore.DocumentData
    >;
};
const ApprovalTableRow: React.FC<ApprovalTableRowProps> = ({ approval }) => {
    const [site, setSite] = useState(null as api.Site);
    const config = useContext(ConfigContext);
    const store = new api.Store(config.environment);

    useEffect(() => {
        (async () => {
            const id = approval.get('site') as string;
            const snap = await store.site(id);
            const data = snap.data() as api.Site;
            setSite(data);
        })();
    }, [approval]);

    if (!site) {
        return <TableRow />;
    }

    return (
        <TableRow>
            <TableCell>{site.displayName}</TableCell>
            <TableCell>
                <Link color="primary" href={site.siteUri} target="_blank">
                    {site.siteUri}
                </Link>
            </TableCell>
            {/* <TableCell>
                <LinkRow
                    pid={approval.get('promotion')}
                    sid={approval.get('site')}
                />
            </TableCell> */}
            <TableCell>{approval.get('status')}</TableCell>
            <TableCell>{lib.ToDate(approval, 'createdAt')}</TableCell>
        </TableRow>
    );
};

type LinkRowProps = {
    pid: string;
    test?: boolean;
};
const LinkRow: React.FC<LinkRowProps> = ({ pid, test }) => {
    const classes = useStyles();
    const [links, setLinks] = useState(
        [] as firebase.firestore.DocumentSnapshot[]
    );
    const config = useContext(ConfigContext);
    const store = new api.Store(config.environment);

    useEffect(() => {
        (async () => {
            const snap = await store.linksAsConversion(pid);
            setLinks(snap);
        })();
    }, [pid]);

    if (!links || links.length == 0) {
        return <div />;
    }
    let query = '';
    if (test) {
        query = '?test=true';
    }

    return (
        <>
            {links.map((row, i) => (
                <div key={i}>
                    <Chip
                        className={classes.textLink}
                        label={`${row.get('uri')}/${row.get('uriPrefix')}/${
                            row.id
                        }${query}`}
                    />{' '}
                    <span>{row.get('redirectUri')}</span>
                    <br />
                </div>
            ))}
        </>
    );
};

type LinkRow2Props = {
    pid: string;
    test?: boolean;
};
const LinkRow2: React.FC<LinkRow2Props> = ({ pid, test }) => {
    const [links, setLinks] = useState(
        [] as firebase.firestore.DocumentSnapshot[]
    );
    const config = useContext(ConfigContext);
    const store = new api.Store(config.environment);

    useEffect(() => {
        (async () => {
            const snap = await store.linksAsConversion(pid);
            setLinks(snap);
        })();
    }, [pid]);

    if (!links || links.length == 0) {
        return <div />;
    }
    let query = '';
    if (test) {
        query = '?test=true';
    }

    return (
        <Chip
            label={links.map((row, i) => (
                <p key={i}>
                    {`${row.get('uri')}/${row.get('uriPrefix')}/${
                        row.id
                    }${query}`}
                    {console.log(
                        'Linkinfo',
                        row.get('siteName'),
                        row.get('partnerName'),
                        row.get('promotionName'),
                        row.get('clientName')
                    )}
                </p>
            ))}
        />
    );
};

type MemberListProps = {
    promotion: api.Promotion;
};
const MemberList: React.FC<MemberListProps> = ({ promotion }) => {
    const classes = useStyles();

    if (!promotion) {
        return <div />;
    }
    return (
        <>
            <Title>Members</Title>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>Role</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {promotion.members.map((row, i) => (
                        <TableRow key={i}>
                            <TableCell>{row}</TableCell>
                            <TableCell>{promotion.roles[row]}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
};
