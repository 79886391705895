import * as React from 'react';

export const CacheContext = React.createContext({
    value: {},
    setValue: (v) => {},
});

export type GlobalConfig = {
    environment: string;
    apiEndpoint: string;
    linkEndpoint: string;
    serviceId: string;
    serviceName: string;
    theme: string;
    documentTitle: string;
    titleBarText: string;
    titleBarLogo: string;
    copyright: string;
    copyrightLink: string;
    sessionMode: string;
};

export const ConfigContext = React.createContext({} as GlobalConfig);
