import * as React from 'react';
import { useHistory } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import { Copyright } from '../Copyright';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    photoSignIn: {
        backgroundImage:
            'url(https://source.unsplash.com/cYa2PSbgrLc/1024x768)',
    },
    photoSignUp: {
        backgroundImage:
            'url(https://source.unsplash.com/yWwob8kwOCk/1024x768)',
    },
    photoPassword: {
        backgroundImage:
            'url(https://source.unsplash.com/rSpMla5RItA/1024x768)',
    },
    photoAction: {
        backgroundImage:
            'url(https://source.unsplash.com/g_DTWgRypfE/1024x768)',
    },
    image: {
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light'
                ? theme.palette.grey[50]
                : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    panel: {
        marginTop: theme.spacing(4),
    },
}));

type AuthBaseViewProps = {
    title: string;
    photoClassName: string;
};
export const AuthBaseView: React.FC<AuthBaseViewProps> = ({
    children,
    title,
    photoClassName,
}) => {
    const classes = useStyles();
    const image = clsx(photoClassName, classes.image);

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={image} />
            <Grid
                item
                xs={12}
                sm={8}
                md={5}
                component={Paper}
                elevation={6}
                square
            >
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {title}
                    </Typography>
                    {children}
                    <Box mt={5}>
                        <Copyright />
                    </Box>
                </div>
            </Grid>
        </Grid>
    );
};

type MessagePanelProps = {
    uri?: string;
};

export const MessagePanel: React.FC<MessagePanelProps> = ({
    children,
    uri,
}) => {
    const classes = useStyles();
    const history = useHistory();
    return (
        <Box className={classes.panel}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="body1">{children}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {uri ? (
                        <Link href={uri} variant="body2">
                            Sign in
                        </Link>
                    ) : (
                        <Link
                            href="/"
                            onClick={(e: React.MouseEvent) => {
                                e.preventDefault();
                                history.push('/');
                            }}
                            variant="body2"
                        >
                            Sign in
                        </Link>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};
