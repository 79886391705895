import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';

import * as api from '../api';
import * as lib from '../lib';
import { Title } from './Title';
import { firestore } from 'firebase';
import { ConfigContext } from './Context';
import { UserContext } from './user';

const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    property: {
        width: '200px',
    },
    textLink: {
        marginBottom: '1px',
    },
}));

type SitesTableProps = {};

export const SitesTable: React.FC<SitesTableProps> = () => {
    const classes = useStyles();
    const history = useHistory();
    const config = useContext(ConfigContext);
    const userStatus = useContext(UserContext);
    const [sites, setSites] = useState(
        [] as firebase.firestore.DocumentSnapshot<firestore.DocumentData>[]
    );
    const store = new api.Store(config.environment);

    useEffect(() => {
        (async () => {
            const data = await store.sites(
                userStatus.user.email,
                config.serviceId
            );
            setSites(data);
        })();
    }, [userStatus]);

    if (!sites || sites.length == 0) {
        return <div />;
    }

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    color="inherit"
                    href="/"
                    onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        history.push('/');
                    }}
                >
                    Dashbord
                </Link>
            </Breadcrumbs>

            <SiteList sites={sites} />

            {/* <div className={classes.seeMore}>
                <Link color="primary" href="#">
                    See more orders
                </Link>
            </div> */}
        </>
    );
};

type SiteDetailViewProps = {};
export const SiteDetailView: React.FC<SiteDetailViewProps> = () => {
    const { sid } = useParams<Record<string, string | undefined>>();
    const classes = useStyles();
    const history = useHistory();
    const config = useContext(ConfigContext);
    const [site, setSite] = useState(
        null as firebase.firestore.DocumentSnapshot
    );
    const [partner, setPartner] = useState(
        null as firebase.firestore.DocumentSnapshot
    );
    const [approvals, setApprovals] = useState(
        [] as firebase.firestore.DocumentSnapshot[]
    );
    const [links, setLinks] = useState(
        [] as firebase.firestore.DocumentSnapshot[]
    );
    const [loading, setLoading] = useState(true);
    const store = new api.Store(config.environment);

    useEffect(() => {
        (async () => {
            const snap = await store.site(sid);
            const data = snap.data() as api.Site;
            setSite(snap);
            if (data.partner) {
                const s = await store.partner(data.partner);
                setPartner(s);
            }
            const a = await store.approvalBySiteId(sid);
            const l = await store.linksAsClick(sid, null);
            setApprovals(a);
            setLinks(l);
            setLoading(false);
        })();
    }, [sid]);

    if (loading) {
        return <div />;
    }
    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    color="inherit"
                    href="/"
                    onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        history.push('/');
                    }}
                >
                    Dashbord
                </Link>
                <Link
                    color="inherit"
                    href="/sites"
                    onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        history.push('/sites');
                    }}
                >
                    Sites
                </Link>
            </Breadcrumbs>
            <br />

            <SiteDetail site={site.data() as api.Site} />
            <br />

            <PartnerDetail partner={partner.data() as api.Partner} />
            <br />

            <ApprovalList approvals={approvals} />
            <br />

            <LinksList links={links} />
            <br />

            <MemberList site={site.data() as api.Site} />
        </>
    );
};

type SiteListProps = {
    sites: firebase.firestore.DocumentSnapshot<
        firebase.firestore.DocumentData
    >[];
};

export const SiteList: React.FC<SiteListProps> = ({ sites }) => {
    const history = useHistory();
    return (
        <>
            <Title>Sites</Title>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>URL</TableCell>
                        <TableCell>Registered at</TableCell>
                        {/* <TableCell align="right">Sale Amount</TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sites.map((row, i) => (
                        <TableRow key={i}>
                            <TableCell>
                                <Link
                                    color="primary"
                                    href={`/sites/${row.id}`}
                                    onClick={(e: React.MouseEvent) => {
                                        e.preventDefault();
                                        history.push(`/sites/${row.id}`);
                                    }}
                                >
                                    {row.get('displayName')}
                                </Link>
                            </TableCell>
                            <TableCell>
                                <Link
                                    color="primary"
                                    href={row.get('siteUri')}
                                    target="_blank"
                                >
                                    {row.get('siteUri')}
                                </Link>
                            </TableCell>
                            <TableCell>
                                {lib.ToDate(row, 'createdAt')}
                            </TableCell>
                            {/* <TableCell align="right">312.4</TableCell> */}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
};

type SiteDetailProps = {
    site: api.Site;
};

const SiteDetail: React.FC<SiteDetailProps> = ({ site }) => {
    const classes = useStyles();
    return (
        <>
            <Title>Site</Title>
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell className={classes.property} variant="head">
                            Name
                        </TableCell>
                        <TableCell>{site.displayName}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">URL</TableCell>
                        <TableCell>
                            <Link
                                color="primary"
                                href={site.siteUri}
                                target="_blank"
                            >
                                {site.siteUri}
                            </Link>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Description</TableCell>
                        <TableCell>{site.description}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Registered at</TableCell>
                        <TableCell>{lib.ToDate3(site.createdAt)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );
};

type PartnerDetailProps = {
    partner: api.Partner;
};

const PartnerDetail: React.FC<PartnerDetailProps> = ({ partner }) => {
    const classes = useStyles();
    return (
        <>
            <Title>Partner</Title>
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell className={classes.property} variant="head">
                            Name
                        </TableCell>
                        <TableCell>{partner.displayName}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Email</TableCell>
                        <TableCell>
                            {partner.organization && partner.organization.email}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Contact person</TableCell>
                        <TableCell>
                            {partner.organization &&
                                partner.organization.contactPerson}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );
};

type ApprovalListProps = {
    approvals: firebase.firestore.DocumentSnapshot<
        firebase.firestore.DocumentData
    >[];
};
const ApprovalList: React.FC<ApprovalListProps> = ({ approvals }) => {
    const classes = useStyles();

    return (
        <>
            <Title>Promotions</Title>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>URL</TableCell>
                        {/* <TableCell>Text Link</TableCell> */}
                        <TableCell>Status</TableCell>
                        <TableCell>Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {approvals.map((row, i) => (
                        <ApprovalTableRow key={i} approval={row} />
                    ))}
                </TableBody>
            </Table>
        </>
    );
};

type ApprovalTableRowProps = {
    approval: firebase.firestore.DocumentSnapshot<
        firebase.firestore.DocumentData
    >;
};
const ApprovalTableRow: React.FC<ApprovalTableRowProps> = ({ approval }) => {
    const [promotion, setPromotion] = useState(null as api.Promotion);
    const config = useContext(ConfigContext);
    const store = new api.Store(config.environment);

    useEffect(() => {
        (async () => {
            const id = approval.get('promotion') as string;
            const snap = await store.promotion(id);
            const data = snap.data() as api.Promotion;
            setPromotion(data);
        })();
    }, [approval]);

    if (!promotion) {
        return <TableRow />;
    }

    return (
        <TableRow>
            <TableCell>{promotion.displayName}</TableCell>
            <TableCell>
                <Link color="primary" href={promotion.siteUri} target="_blank">
                    {promotion.siteUri}
                </Link>
            </TableCell>
            {/* <TableCell>
                <LinkRow
                    pid={approval.get('promotion')}
                    sid={approval.get('site')}
                />
            </TableCell> */}
            <TableCell>{approval.get('status')}</TableCell>
            <TableCell>{lib.ToDate(approval, 'createdAt')}</TableCell>
        </TableRow>
    );
};

type LinkRowProps = {
    pid: string;
    sid: string;
};
const LinkRow: React.FC<LinkRowProps> = ({ pid, sid }) => {
    const classes = useStyles();
    const [links, setLinks] = useState(
        [] as firebase.firestore.DocumentSnapshot[]
    );
    const config = useContext(ConfigContext);
    const store = new api.Store(config.environment);

    useEffect(() => {
        (async () => {
            const snap = await store.linksAsClick(sid, pid);
            setLinks(snap);
        })();
    }, [pid, sid]);

    if (!links || links.length == 0) {
        return <div />;
    }

    return (
        <>
            {links.map((row, i) => (
                <Chip
                    className={classes.textLink}
                    key={i}
                    label={`${row.get('uri')}/${row.get('uriPrefix')}/${
                        row.id
                    }`}
                />
            ))}
            {/* <Chip
                label={links.map((row, i) => (
                    <p key={i}>
                        {`${row.get('uri')}/${row.get('uriPrefix')}/${row.id}`}
                        {console.log(
                            'Linkinfo',
                            row.get('siteName'),
                            row.get('partnerName'),
                            row.get('promotionName'),
                            row.get('clientName')
                        )}
                    </p>
                ))}
            /> */}
        </>
    );
};

type LinksListProps = {
    links: firebase.firestore.DocumentSnapshot<
        firebase.firestore.DocumentData
    >[];
};
const LinksList: React.FC<LinksListProps> = ({ links }) => {
    const classes = useStyles();
    console.log(links.length);

    console.log(links);

    return (
        <>
            <Title>Text links</Title>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        {/* <TableCell>Client</TableCell> */}
                        <TableCell>Promotion name</TableCell>
                        <TableCell>Text link</TableCell>
                        <TableCell>Landing page</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {links.map((row, i) => (
                        <TableRow key={i}>
                            {/* <TableCell>
                                {row.get('clientName') as string}
                            </TableCell> */}
                            <TableCell>
                                {row.get('promotionName') as string}
                            </TableCell>
                            <TableCell>
                                {`${row.get('uri')}/${row.get('uriPrefix')}/${
                                    row.id
                                }`}
                            </TableCell>
                            <TableCell>
                                <Link
                                    color="primary"
                                    href={row.get('redirectUri')}
                                    target="_blank"
                                >
                                    {row.get('redirectUri')}
                                </Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
};

type MemberListProps = {
    site: api.Site;
};
const MemberList: React.FC<MemberListProps> = ({ site }) => {
    const classes = useStyles();

    if (!site) {
        return <div />;
    }
    return (
        <>
            <Title>Members</Title>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>Role</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {site.members.map((row, i) => (
                        <TableRow key={i}>
                            <TableCell>{row}</TableCell>
                            <TableCell>{site.roles[row]}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
};
