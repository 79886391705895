import * as firebase from 'firebase/app';
import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { UserContext } from './Context';
import { SignIn } from './SignIn';
import { UserStatus, User } from './types';

export const UserProvider: React.FC = ({ children }) => {
    const { userStatus, loading } = useUserAuth();

    if (loading) {
        return <LinearProgress />;
    }
    if (!userStatus) {
        return <div />;
    }

    return (
        <UserContext.Provider value={userStatus}>
            {children}
        </UserContext.Provider>
    );
};

export const Autnenticate: React.FC = ({ children }) => {
    const userStatus = useContext(UserContext);
    if (!userStatus || !userStatus.user) {
        return <SignIn />;
    }
    return <>{children}</>;
};

const useUserAuth = () => {
    const [userStatus, setUserStatus] = useState(null as UserStatus);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        console.log('App.useEffect');
        const unsbscribe = firebase.auth().onAuthStateChanged((user) => {
            console.log('App.useEffect: onAuthStateChanged', user);
            if (user) {
                (async () => {
                    setLoading(true);
                    const attr = await firebase
                        .firestore()
                        .collection('users')
                        .doc(user.uid)
                        .get();
                    setUserStatus({
                        user: user,
                        attributes: attr.data() as User,
                    });
                    setLoading(false);
                })();
            } else {
                setUserStatus({ user: null, attributes: null });
            }
        });
        return () => unsbscribe();
    }, []);

    return { userStatus, loading };
};
