import * as firebase from 'firebase/app';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Messagebar from '../Messagebar';
import { AuthBaseView, MessagePanel, useStyles } from './AuthBase';

export const PasswordReset: React.FC = () => {
    const classes = useStyles();
    const [error, setError] = useState('');
    const { register, handleSubmit, errors } = useForm();
    const history = useHistory();
    const [status, setStatus] = useState(false);

    const onSubmit = async (data: { email: string }) => {
        try {
            const auth = firebase.auth();
            await auth.sendPasswordResetEmail(data.email, {
                url: window.location.origin,
            });
            setStatus(true);
        } catch (err) {
            setError(err.code);
        }
    };

    return (
        <AuthBaseView
            title="Reset Password"
            photoClassName={classes.photoPassword}
        >
            {status ? (
                <MessagePanel>Done. Please check your mail box.</MessagePanel>
            ) : (
                <form
                    className={classes.form}
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                Enter your Email Address.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                inputRef={register({
                                    required: 'Input your email address',
                                })}
                                error={errors.email && true}
                                helperText={
                                    errors.email && errors.email.message
                                }
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Send Email
                    </Button>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link
                                href=""
                                onClick={(e: React.MouseEvent) => {
                                    e.preventDefault();
                                    history.push('/');
                                }}
                                variant="body2"
                            >
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            )}
            <Messagebar code={error} onClose={() => setError('')} />
        </AuthBaseView>
    );
};
