import { GlobalConfig } from './Context';

const configServiceId = () => {
    if (
        process.env.SERVICE_ID !== undefined &&
        process.env.SERVICE_ID !== null &&
        process.env.SERVICE_ID !== ''
    ) {
        console.log('serviceId: env.SERVICE_ID:', process.env.SERVICE_ID);
        return process.env.SERVICE_ID;
    }
    const hostname = window.location.hostname;
    if (
        [
            'console.dock.promo',
            'console-stage.dock.promo',
            'console.ap-dock.com',
            'console-stage.ap-dock.com',
        ].indexOf(hostname) >= 0
    ) {
        console.log('serviceId: dock.promo');
        return 'lrAvrFbNZpNvm0XyWnKE';
    } else if (
        ['console.ad-link.net', 'console-stage.ad-link.net'].indexOf(
            hostname
        ) >= 0
    ) {
        console.log('serviceId: ad-link.net');
        return 'oGq5bRd5VYjm1RIF3gIa';
    }
    console.log('serviceId: default, hostname =', hostname);
    return 'oGq5bRd5VYjm1RIF3gIa';
};

export const configInit = () => {
    if (!process.env.ENV) {
        console.error('fatal error: no environment');
        return null;
    }

    const serviceId = configServiceId();
    if (serviceId === 'lrAvrFbNZpNvm0XyWnKE') {
        return {
            environment: process.env.ENV,
            apiEndpoint: process.env.API_ENDPOINT,
            linkEndpoint:
                process.env.ENV === 'stage'
                    ? 'go-stage.dock.promo'
                    : 'go.dock.promo',
            serviceId: serviceId,
            serviceName: 'DOCK',
            theme: 'dock',
            documentTitle: 'DOCK Console',
            titleBarText: '',
            titleBarLogo: 'dock_logo_yoko',
            copyright: 'actual proof',
            copyrightLink: 'https://actualproof.jp/',
            sessionMode: process.env.SESSION_MODE,
        } as GlobalConfig;
    }
    return {
        environment: process.env.ENV,
        apiEndpoint: process.env.API_ENDPOINT,
        linkEndpoint:
            process.env.ENV === 'stage'
                ? 'go-stage.ad-link.net'
                : 'go.ad-link.net',
        serviceId: serviceId,
        serviceName: 'AD-LINK.NET',
        theme: '',
        documentTitle: 'AD-LINK.NET Console',
        titleBarText: 'AD-LINK.NET Console',
        titleBarLogo: '',
        copyright: 'Winland Co., Ltd.',
        copyrightLink: 'http://winland.jp/',
        sessionMode: process.env.SESSION_MODE,
    } as GlobalConfig;
};
