import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';

import * as api from '../api';
import * as lib from '../lib';
import { Title } from './Title';
import { firestore } from 'firebase';
import { CacheContext, ConfigContext } from './Context';
import { Currency, wrapAPI } from './Report';
import { UserContext } from './user';

const useStyles2 = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 260,
    },
    rowtitle: {
        width: '300px',
    },
}));

type SiteListPanelProps = {};

const options = [
    'None',
    'Atria',
    'Callisto',
    'Dione',
    'Ganymede',
    'Hangouts Call',
    'Luna',
    'Oberon',
    'Phobos',
    'Pyxis',
    'Sedna',
    'Titania',
    'Triton',
    'Umbriel',
];
const ITEM_HEIGHT = 48;

export const SiteListPanel: React.FC<SiteListPanelProps> = () => {
    const userStatus = useContext(UserContext);
    const config = useContext(ConfigContext);
    // const classes = useStyles();
    const [sites, setSites] = useState(
        [] as firebase.firestore.DocumentSnapshot<firestore.DocumentData>[]
    );
    const [current, setCurrent] = useState(
        null as firebase.firestore.DocumentData
    );
    const store = new api.Store(config.environment);
    const classes = useStyles2();
    const history = useHistory();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        console.log('handleClose', event.target);
        if (event.target && event.target.value !== undefined) {
            setCurrent(sites[event.target.value]);
        }
        setAnchorEl(null);
    };

    useEffect(() => {
        console.log('SiteListPanel.useEffect', userStatus);
        if (userStatus && userStatus.user) {
            (async () => {
                const data = await store.sites(
                    userStatus.user.email,
                    config.serviceId
                );
                setSites(data);
                if (data.length > 0) {
                    setCurrent(data[0]);
                }
            })();
        }
    }, [userStatus]);

    if (!sites || sites.length == 0) {
        return <div />;
    }

    console.log('SiteListPanel', sites);

    return (
        <Paper className={classes.paper}>
            <Title>Site's summary</Title>
            <Box component="span">
                {current && current.get('displayName')}
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <ArrowDropDownIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '60ch',
                        },
                    }}
                >
                    {sites.map((site, i) => (
                        <MenuItem
                            key={site.id}
                            selected={i === 0}
                            value={i}
                            onClick={handleClose}
                        >
                            {site.get('displayName')}
                        </MenuItem>
                    ))}
                </Menu>
            </Box>
            {/* {sites.map((site, i) => ())} */}
            {/* <Title>{sites[0].get('displayName')}</Title> */}
            {current && <SummarySiteInfo site={current} />}
        </Paper>
    );
};

type SummaryInfoProps = {
    site: firebase.firestore.DocumentData;
};

export const SummarySiteInfo: React.FC<SummaryInfoProps> = ({ site }) => {
    const classes = useStyles2();
    const history = useHistory();
    const [day, setDay] = useState([] as any);
    const [week, setWeek] = useState([] as any);
    const [month, setMonth] = useState([] as any);
    const [loading, setLoading] = useState(true);
    const cache = useContext(CacheContext);
    const config = useContext(ConfigContext);
    // console.log('Today', lib.Today().toISOString());
    // console.log('CurrentMonth', lib.CurrentMonth().toISOString());
    // console.log('NextMonth', lib.NextMonth().toISOString());
    // console.log('Tommorow', lib.Tommorow().toISOString());
    // console.log('BeforeDay', lib.BeforeDay(7).toISOString());

    useEffect(() => {
        (async () => {
            setLoading(true);
            const [d, w, m] = await Promise.all([
                wrapAPI(
                    config.apiEndpoint,
                    {
                        start: lib.Today().toISOString(),
                        end: lib.Tommorow().toISOString(),
                        promotionId: null,
                        siteId: [site.id],
                        dateLabel: 'Today',
                        promotionLabel: 'z',
                        siteLabel: '',
                        serviceId: config.serviceId,
                        sessionMode: config.sessionMode,
                    },
                    cache
                ),
                wrapAPI(
                    config.apiEndpoint,
                    {
                        start: lib.BeforeDay(7).toISOString(),
                        end: lib.Tommorow().toISOString(),
                        promotionId: null,
                        siteId: [site.id],
                        dateLabel: 'Last 7 days',
                        promotionLabel: 'z',
                        siteLabel: '',
                        serviceId: config.serviceId,
                        sessionMode: config.sessionMode,
                    },
                    cache
                ),
                wrapAPI(
                    config.apiEndpoint,
                    {
                        start: lib.BeforeDay(30).toISOString(),
                        end: lib.Tommorow().toISOString(),
                        promotionId: null,
                        siteId: [site.id],
                        dateLabel: 'Last 30 days',
                        promotionLabel: 'z',
                        siteLabel: '',
                        serviceId: config.serviceId,
                        sessionMode: config.sessionMode,
                    },
                    cache
                ),
            ]);
            if (d.length > 0) {
                setDay(d[0]);
            } else {
                console.log('day??', d);
                setDay(['Today', 0, 0, 0, 0, 0, 0, 0]);
            }
            if (w.length > 0) {
                setWeek(w[0]);
            } else {
                console.log('week??', w);
                setWeek(['Last 7 days', 0, 0, 0, 0, 0, 0, 0]);
            }
            if (m.length > 0) {
                setMonth(m[0]);
            } else {
                console.log('month??', m);
                setMonth(['Last 30 days', 0, 0, 0, 0, 0, 0, 0]);
            }
            setLoading(false);
        })();
    }, [site.id]);

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Impression</TableCell>
                        <TableCell>Click</TableCell>
                        <TableCell>Conversion</TableCell>
                        <TableCell>CVR</TableCell>
                        <TableCell>Approved</TableCell>
                        <TableCell>Denied</TableCell>
                        <TableCell>Pending</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>{day[0] ? day[0] : ''}</TableCell>
                        <TableCell>0</TableCell>
                        <TableCell>{day[5] ? day[5] : 0}</TableCell>
                        <TableCell>
                            {day[6] ? day[6] : 0} <Currency num={day[11]} />
                        </TableCell>
                        <TableCell>{lib.CVR(day[5], day[6])}</TableCell>
                        <TableCell>0</TableCell>
                        <TableCell>0</TableCell>
                        <TableCell>
                            {day[6] ? day[6] : 0} <Currency num={day[17]} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{week[0] ? week[0] : ''}</TableCell>
                        <TableCell>0</TableCell>
                        <TableCell>{week[5] ? week[5] : 0}</TableCell>
                        <TableCell>
                            {week[6] ? week[6] : 0} <Currency num={week[11]} />
                        </TableCell>
                        <TableCell>{lib.CVR(week[5], week[6])}</TableCell>
                        <TableCell>0</TableCell>
                        <TableCell>0</TableCell>
                        <TableCell>
                            {week[6] ? week[6] : 0} <Currency num={week[17]} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{month[0] ? month[0] : ''}</TableCell>
                        <TableCell>0</TableCell>
                        <TableCell>{month[5] ? month[5] : 0}</TableCell>
                        <TableCell>
                            {month[6] ? month[6] : 0}{' '}
                            <Currency num={month[11]} />
                        </TableCell>
                        <TableCell>{lib.CVR(month[5], month[6])}</TableCell>
                        <TableCell>0</TableCell>
                        <TableCell>0</TableCell>
                        <TableCell>
                            {month[6] ? month[6] : 0}{' '}
                            <Currency num={month[17]} />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <div className={classes.seeMore}>
                <Link
                    color="primary"
                    href=""
                    onClick={(e) => {
                        e.preventDefault();
                        history.push(`/reports/sites/${site.id}`);
                    }}
                >
                    See more reports
                </Link>
            </div>
        </>
    );
};

export const ProductListPanel: React.FC<SiteListPanelProps> = () => {
    const userStatus = useContext(UserContext);
    const config = useContext(ConfigContext);
    // const classes = useStyles();
    const [promotions, setPromotions] = useState(
        [] as firebase.firestore.DocumentSnapshot<firestore.DocumentData>[]
    );
    const [current, setCurrent] = useState(
        null as firebase.firestore.DocumentData
    );
    const store = new api.Store(config.environment);
    const classes = useStyles2();
    const history = useHistory();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        console.log('handleClose', event.target);
        if (event.target && event.target.value !== undefined) {
            setCurrent(promotions[event.target.value]);
        }
        setAnchorEl(null);
    };

    useEffect(() => {
        console.log('SiteListPanel.useEffect', userStatus);
        if (userStatus && userStatus.user) {
            (async () => {
                const data = await store.promotions(
                    userStatus.user.email,
                    config.serviceId
                );
                setPromotions(data);
                if (data.length > 0) {
                    setCurrent(data[0]);
                }
            })();
        }
    }, [userStatus]);

    if (!promotions || promotions.length == 0) {
        return <div />;
    }

    console.log('ProductListPanel', promotions);

    return (
        <Paper className={classes.paper}>
            <Title>Promotion's summary</Title>
            <Box component="span">
                {current && current.get('displayName')}
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <ArrowDropDownIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '60ch',
                        },
                    }}
                >
                    {promotions.map((site, i) => (
                        <MenuItem
                            key={site.id}
                            selected={i === 0}
                            value={i}
                            onClick={handleClose}
                        >
                            {site.get('displayName')}
                        </MenuItem>
                    ))}
                </Menu>
            </Box>
            {/* {sites.map((site, i) => ())} */}
            {/* <Title>{sites[0].get('displayName')}</Title> */}
            {current && <SummaryProductInfo promotion={current} />}
        </Paper>
    );
};

type SummaryProductInfoProps = {
    promotion: firebase.firestore.DocumentData;
};

export const SummaryProductInfo: React.FC<SummaryProductInfoProps> = ({
    promotion,
}) => {
    const classes = useStyles2();
    const history = useHistory();
    const [day, setDay] = useState([] as any);
    const [week, setWeek] = useState([] as any);
    const [month, setMonth] = useState([] as any);
    const [loading, setLoading] = useState(true);
    const cache = useContext(CacheContext);
    const config = useContext(ConfigContext);
    // console.log('Today', lib.Today().toISOString());
    // console.log('CurrentMonth', lib.CurrentMonth().toISOString());
    // console.log('NextMonth', lib.NextMonth().toISOString());
    // console.log('Tommorow', lib.Tommorow().toISOString());
    // console.log('BeforeDay', lib.BeforeDay(7).toISOString());

    useEffect(() => {
        (async () => {
            setLoading(true);
            const [d, w, m] = await Promise.all([
                wrapAPI(
                    config.apiEndpoint,
                    {
                        start: lib.Today().toISOString(),
                        end: lib.Tommorow().toISOString(),
                        promotionId: [promotion.id],
                        siteId: null,
                        dateLabel: 'Today',
                        promotionLabel: '',
                        siteLabel: 'z',
                        serviceId: config.serviceId,
                        sessionMode: config.sessionMode,
                    },
                    cache
                ),
                wrapAPI(
                    config.apiEndpoint,
                    {
                        start: lib.BeforeDay(7).toISOString(),
                        end: lib.Tommorow().toISOString(),
                        promotionId: [promotion.id],
                        siteId: null,
                        dateLabel: 'Last 7 days',
                        promotionLabel: '',
                        siteLabel: 'z',
                        serviceId: config.serviceId,
                        sessionMode: config.sessionMode,
                    },
                    cache
                ),
                wrapAPI(
                    config.apiEndpoint,
                    {
                        start: lib.BeforeDay(30).toISOString(),
                        end: lib.Tommorow().toISOString(),
                        promotionId: [promotion.id],
                        siteId: null,
                        dateLabel: 'Last 30 days',
                        promotionLabel: '',
                        siteLabel: 'z',
                        serviceId: config.serviceId,
                        sessionMode: config.sessionMode,
                    },
                    cache
                ),
            ]);
            if (d.length > 0) {
                setDay(d[0]);
            } else {
                console.log('day??', d);
                setDay(['Today', 0, 0, 0, 0, 0, 0, 0]);
            }
            if (w.length > 0) {
                setWeek(w[0]);
            } else {
                console.log('week??', w);
                setWeek(['Last 7 days', 0, 0, 0, 0, 0, 0, 0]);
            }
            if (m.length > 0) {
                setMonth(m[0]);
            } else {
                console.log('month??', m);
                setMonth(['Last 30 days', 0, 0, 0, 0, 0, 0, 0]);
            }
            setLoading(false);
        })();
    }, [promotion.id]);

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Impression</TableCell>
                        <TableCell>Click</TableCell>
                        <TableCell>Conversion</TableCell>
                        <TableCell>CVR</TableCell>
                        <TableCell>Approved</TableCell>
                        <TableCell>Denied</TableCell>
                        <TableCell>Pending</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>{day[0] ? day[0] : ''}</TableCell>
                        <TableCell>0</TableCell>
                        <TableCell>{day[5] ? day[5] : 0}</TableCell>
                        <TableCell>
                            {day[6] ? day[6] : 0} <Currency num={day[10]} />
                        </TableCell>
                        <TableCell>{lib.CVR(day[5], day[6])}</TableCell>
                        <TableCell>0</TableCell>
                        <TableCell>0</TableCell>
                        <TableCell>
                            {day[6] ? day[6] : 0} <Currency num={day[16]} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{week[0] ? week[0] : ''}</TableCell>
                        <TableCell>0</TableCell>
                        <TableCell>{week[5] ? week[5] : 0}</TableCell>
                        <TableCell>
                            {week[6] ? week[6] : 0} <Currency num={week[10]} />
                        </TableCell>
                        <TableCell>{lib.CVR(week[5], week[6])}</TableCell>
                        <TableCell>0</TableCell>
                        <TableCell>0</TableCell>
                        <TableCell>
                            {week[6] ? week[6] : 0} <Currency num={week[16]} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{month[0] ? month[0] : ''}</TableCell>
                        <TableCell>0</TableCell>
                        <TableCell>{month[5] ? month[5] : 0}</TableCell>
                        <TableCell>
                            {month[6] ? month[6] : 0}{' '}
                            <Currency num={month[10]} />
                        </TableCell>
                        <TableCell>{lib.CVR(month[5], month[6])}</TableCell>
                        <TableCell>0</TableCell>
                        <TableCell>0</TableCell>
                        <TableCell>
                            {month[6] ? month[6] : 0}{' '}
                            <Currency num={month[16]} />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <div className={classes.seeMore}>
                <Link
                    color="primary"
                    href=""
                    onClick={(e) => {
                        e.preventDefault();
                        history.push(`/reports/promotions/${promotion.id}`);
                    }}
                >
                    See more reports
                </Link>
            </div>
        </>
    );
};
