import * as React from 'react';
import { useContext } from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { ConfigContext } from './Context';

export const Copyright = () => {
    const config = useContext(ConfigContext);

    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href={config.copyrightLink} target="_blank">
                {config.copyright}
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
};
