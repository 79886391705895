import { createMuiTheme } from '@material-ui/core/styles';

export const dockTheme = createMuiTheme({
    palette: {
        primary: {
            light: '#66cbf2',
            main: '#249abf',
            dark: '#006c8f',
            contrastText: '#000000',
        },
        secondary: {
            light: '#ffa750',
            main: '#ee771e',
            dark: '#b54900',
            contrastText: '#000000',
        },
    },
});
