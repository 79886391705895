import * as firebase from 'firebase/app';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';

import Messagebar from '../Messagebar';
import { AuthBaseView, MessagePanel, useStyles } from './AuthBase';

export const SignUp: React.FC = () => {
    const classes = useStyles();
    const [error, setError] = useState('');
    const { register, handleSubmit, errors } = useForm();
    const history = useHistory();
    const [status, setStatus] = useState(false);

    const onSubmit = async (data: {
        name: string;
        email: string;
        password: string;
    }) => {
        try {
            const auth = firebase.auth();
            await auth.createUserWithEmailAndPassword(
                data.email,
                data.password
            );
            setStatus(true);
        } catch (err) {
            setError(err.code);
        }
    };

    return (
        <AuthBaseView
            title="Sign up AD-LINK.NET"
            photoClassName={classes.photoSignUp}
        >
            {status ? (
                <MessagePanel>Done. You can sign in AD-LINK.NET.</MessagePanel>
            ) : (
                <form
                    className={classes.form}
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete="fname"
                                name="name"
                                variant="outlined"
                                required
                                fullWidth
                                id="name"
                                label="Your Name"
                                autoFocus
                                inputRef={register({
                                    required: 'Input your name',
                                })}
                                error={errors.name && true}
                                helperText={errors.name && errors.name.message}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                inputRef={register({
                                    required: 'Input your email address',
                                })}
                                error={errors.email && true}
                                helperText={
                                    errors.email && errors.email.message
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                inputRef={register({
                                    required: 'Input your password',
                                })}
                                error={errors.password && true}
                                helperText={
                                    errors.password && errors.password.message
                                }
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value="allowExtraEmails"
                                        color="primary"
                                    />
                                }
                                label="I want to receive inspiration, marketing promotions and updates via email."
                            />
                        </Grid> */}
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Sign Up AD-LINK.NET
                    </Button>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link
                                href=""
                                onClick={(e: React.MouseEvent) => {
                                    e.preventDefault();
                                    history.push('/');
                                }}
                                variant="body2"
                            >
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            )}
            <Messagebar code={error} onClose={() => setError('')} />
        </AuthBaseView>
    );
};
