import * as XLSX from 'xlsx';

export const DateFormat = (d: number | Date): string => {
    if (!d) {
        return '';
    }

    return new Intl.DateTimeFormat().format(d);
};
import * as firebase from 'firebase/app';
import * as api from './api';

export const DateTimeFormat = (d: number | Date): string => {
    if (!d) {
        return '';
    }

    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    };
    return new Intl.DateTimeFormat(undefined, options).format(d);
};

export const MonthFormat = (d: number | Date): string => {
    if (!d) {
        return '';
    }

    const options = {
        year: 'numeric',
        month: 'numeric',
    };
    return new Intl.DateTimeFormat(undefined, options).format(d);
};

export const ToDate = (
    snap: firebase.firestore.DocumentSnapshot,
    name: string
): string => {
    if (!snap) {
        return '';
    }
    const d = snap.get(name) as firebase.firestore.Timestamp;
    if (!d) {
        return '';
    }
    return DateFormat(d.toDate());
};

export const ToDate2 = (val: firebase.firestore.FieldValue): string => {
    if (!val) {
        return '';
    }
    const d = val as firebase.firestore.Timestamp;
    if (!d) {
        return '';
    }
    return DateFormat(d.toDate());
};

export const ToDate3 = (val: api.Timestamp): string => {
    if (!val) {
        return '';
    }
    const d = val as firebase.firestore.Timestamp;
    if (!d) {
        return '';
    }
    return DateFormat(d.toDate());
};

export const Today = () => {
    let d = new Date();
    return new Date(d.getFullYear(), d.getMonth(), d.getDate());
};

export const Month = (i: number) => {
    let d = new Date();
    return new Date(d.getFullYear(), d.getMonth() + i, 1);
};

export const PrevMonth = () => {
    let d = new Date();
    return new Date(d.getFullYear(), d.getMonth() - 1, 1);
};

export const CurrentMonth = () => {
    let d = new Date();
    return new Date(d.getFullYear(), d.getMonth(), 1);
};

export const NextMonth = () => {
    let d = new Date();
    d.setMonth(d.getMonth() + 1);
    return new Date(d.getFullYear(), d.getMonth(), 1);
};

export const Tommorow = () => {
    let d = new Date();
    d.setDate(d.getDate() + 1);
    return new Date(d.getFullYear(), d.getMonth(), d.getDate());
};

export const BeforeDay = (n: number) => {
    let d = new Date();
    d.setDate(d.getDate() - n);
    return new Date(d.getFullYear(), d.getMonth(), d.getDate());
};

export const CVR = (click: number, conversion: number) => {
    if (!click || click === 0) {
        return '-';
    }
    return new Intl.NumberFormat(undefined, {
        style: 'percent',
        minimumFractionDigits: 1,
    }).format(conversion / click);
};

export const saveAsXlsx = (
    data: any[],
    filename: string,
    sheetname?: string
) => {
    if (!sheetname) {
        sheetname = filename;
    }
    var wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(data, {
        cellDates: true,
        dateNF: 'YYYY-MM-DD hh:mm:ss',
    });
    XLSX.utils.book_append_sheet(wb, ws, sheetname);
    XLSX.writeFile(wb, `${filename}.xlsx`);
};

export const xlsxTimestamp = (d: string) => {
    let date = new Date(d);
    date.setMilliseconds(0);
    return date;
};
