import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Hidden from '@material-ui/core/Hidden';
import {
    MainListItems,
    UserListItems,
    secondaryListItems,
    SiteMenu,
    PromotionMenu,
} from './listItems';
import { Copyright } from './Copyright';
import { PromotionsTable, PromotionDetail } from './Promotion';
import { SitesTable, SiteDetailView } from './Site';
import {
    ReportSiteView,
    ReportSitePromotionView,
    ReportSitePromotionDetailView,
    ReportPromotionView,
    ReportPromotionSiteView,
    ReportPromotionSiteDetailView,
} from './Report';
import { LogSiteView, LogPromotionView } from './Log';
import { Dashboard } from './Dashboard';
import { ConfigContext } from './Context';
import { UserContext } from './user';
import * as api from '../api';
const images = require('../assets/images/*.png');

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    appBarDock: {
        background: theme.palette.background.paper,
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
}));

type MainProps = {};

export const Main: React.FC<MainProps> = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Hidden smUp>
                <MobileFrame />
            </Hidden>
            <Hidden xsDown>
                <WideFrame />
            </Hidden>
            <MainContent />
        </div>
    );
};

const TitleBar: React.FC<MainProps> = () => {
    const classes = useStyles();
    const config = useContext(ConfigContext);

    return (
        <>
            {config.titleBarLogo && (
                <Link to="/">
                    <img
                        src={images[config.titleBarLogo]}
                        height="32px"
                        width="auto"
                    />
                </Link>
            )}
            <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
            >
                {config.titleBarText}
            </Typography>
            {/* <IconButton color="inherit">
                <Badge badgeContent={4} color="secondary">
                    <NotificationsIcon />
                </Badge>
            </IconButton> */}
        </>
    );
};

const MainMenu: React.FC<MainProps> = () => {
    const [loading, setLoading] = useState(true);
    const [sites, setSites] = useState(
        null as firebase.firestore.DocumentSnapshot[]
    );
    const [promotions, setPromotions] = useState(
        null as firebase.firestore.DocumentSnapshot[]
    );

    const config = useContext(ConfigContext);
    const userStatus = useContext(UserContext);
    const store = new api.Store(config.environment);

    useEffect(() => {
        (async () => {
            const sites = await store.sites(
                userStatus.user.email,
                config.serviceId
            );
            const promotions = await store.promotions(
                userStatus.user.email,
                config.serviceId
            );
            setSites(sites);
            setPromotions(promotions);
            setLoading(false);
        })();
    }, [userStatus]);

    return (
        <>
            <List>
                <MainListItems />
            </List>
            {sites && sites.length > 0 && (
                <>
                    <Divider />
                    <List>
                        <SiteMenu />
                    </List>
                </>
            )}
            {promotions && promotions.length > 0 && (
                <>
                    <Divider />
                    <List>
                        <PromotionMenu />
                    </List>
                </>
            )}
            {/* <Divider />
            <List>{secondaryListItems}</List> */}
            <Divider />
            <List>
                <UserListItems />
            </List>
        </>
    );
};

const MobileFrame: React.FC<MainProps> = () => {
    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const config = useContext(ConfigContext);

    const handleDrawerToggle = () => {
        console.log('handleDrawerToggle', mobileOpen, '->', !mobileOpen);
        setMobileOpen(!mobileOpen);
    };
    const isDock = config.theme == 'dock';
    return (
        <>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, isDock && classes.appBarDock)}
            >
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <TitleBar />
                </Toolbar>
            </AppBar>
            <Drawer
                variant="temporary"
                classes={{
                    paper: classes.drawerPaper,
                }}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleDrawerToggle}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <MainMenu />
            </Drawer>
        </>
    );
};

const WideFrame: React.FC<MainProps> = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const config = useContext(ConfigContext);

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const isDock = config.theme == 'dock';
    return (
        <>
            <AppBar
                position="absolute"
                className={clsx(
                    classes.appBar,
                    open && classes.appBarShift,
                    isDock && classes.appBarDock
                )}
            >
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(
                            classes.menuButton,
                            open && classes.menuButtonHidden
                        )}
                    >
                        <MenuIcon />
                    </IconButton>
                    <TitleBar />
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(
                        classes.drawerPaper,
                        !open && classes.drawerPaperClose
                    ),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>

                <Divider />
                <MainMenu />
            </Drawer>
        </>
    );
};

const MainContent: React.FC<MainProps> = () => {
    const classes = useStyles();
    return (
        <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <Switch>
                    <Route exact path="/">
                        <Dashboard />
                    </Route>
                    <Route exact path="/reports/sites">
                        <ReportSiteView />
                    </Route>
                    <Route exact path="/reports/sites/:sid">
                        <ReportSitePromotionView />
                    </Route>
                    <Route exact path="/reports/sites/:sid/:pid">
                        <ReportSitePromotionDetailView />
                    </Route>
                    <Route exact path="/reports/promotions">
                        <ReportPromotionView />
                    </Route>
                    <Route exact path="/reports/promotions/:pid">
                        <ReportPromotionSiteView />
                    </Route>
                    <Route exact path="/reports/promotions/:pid/:sid">
                        <ReportPromotionSiteDetailView />
                    </Route>
                    <Route exact path="/promotions">
                        <PromotionsTable />
                    </Route>
                    <Route exact path="/promotions/:pid">
                        <PromotionDetail />
                    </Route>
                    <Route exact path="/sites">
                        <SitesTable />
                    </Route>
                    <Route exact path="/sites/:sid">
                        <SiteDetailView />
                    </Route>
                    <Route exact path="/logs/sites">
                        <LogSiteView />
                    </Route>
                    <Route exact path="/logs/sites/:id">
                        <LogSiteView />
                    </Route>
                    <Route exact path="/logs/promotions">
                        <LogPromotionView />
                    </Route>
                    <Route exact path="/logs/promotions/:id">
                        <LogPromotionView />
                    </Route>
                </Switch>

                <Box pt={4}>
                    <Copyright />
                </Box>
            </Container>
        </main>
    );
};
