import * as firebase from 'firebase/app';
import * as React from 'react';
import { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';

import Messagebar from '../Messagebar';
import { AuthBaseView, useStyles } from './AuthBase';
import { ConfigContext } from '../Context';

export const SignIn: React.FC = () => {
    const classes = useStyles();
    const [error, setError] = useState('');
    const { register, handleSubmit, errors } = useForm();
    const history = useHistory();
    const config = useContext(ConfigContext);

    const onSubmit = async (data) => {
        const mode = data.remember
            ? firebase.auth.Auth.Persistence.LOCAL
            : firebase.auth.Auth.Persistence.SESSION;
        try {
            const auth = firebase.auth();
            await auth.setPersistence(mode);
            await auth.signInWithEmailAndPassword(data.email, data.password);
        } catch (err) {
            setError(err.code);
        }
    };

    return (
        <AuthBaseView
            title={`Sign in ${config.serviceName}`}
            photoClassName={classes.photoSignIn}
        >
            <form
                className={classes.form}
                noValidate
                onSubmit={handleSubmit(onSubmit)}
            >
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    inputRef={register({
                        required: 'Input your email address',
                    })}
                    error={errors.email && true}
                    helperText={errors.email && errors.email.message}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    inputRef={register({
                        required: 'Input your password',
                    })}
                    error={errors.password && true}
                    helperText={errors.password && errors.password.message}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            name="remember"
                            value="remember"
                            color="primary"
                            inputRef={register}
                        />
                    }
                    label="Remember me"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    {`Sign in ${config.serviceName}`}
                </Button>
                <Grid container>
                    <Grid item xs>
                        <Link
                            href=""
                            onClick={(e: React.MouseEvent) => {
                                e.preventDefault();
                                history.push('/password');
                            }}
                        >
                            Forgot password?
                        </Link>
                    </Grid>
                    {/* <Grid item>
                        <Link
                            href=""
                            onClick={(e: React.MouseEvent) => {
                                e.preventDefault();
                                history.push('/signup');
                            }}
                        >
                            {"Don't have an account? Sign Up"}
                        </Link>
                    </Grid> */}
                </Grid>
                <Messagebar code={error} onClose={() => setError('')} />
            </form>
        </AuthBaseView>
    );
};
