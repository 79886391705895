import * as firebase from 'firebase/app';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Messagebar from '../Messagebar';
import { AuthBaseView, MessagePanel, useStyles } from './AuthBase';

export const AuthAction: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { register, handleSubmit, errors } = useForm();
    const [error, setError] = useState('');
    const [status, setStatus] = useState(false);
    const params = new URLSearchParams(location.search);
    const continueUrl = params.get('continueUrl')
        ? params.get('continueUrl')
        : '/';

    const onSubmit = async (data: { password: string }) => {
        try {
            const auth = firebase.auth();
            await auth.confirmPasswordReset(
                params.get('oobCode'),
                data.password
            );
            setStatus(true);
        } catch (err) {
            setError(err.code);
        }
    };

    if (params.get('mode') !== 'resetPassword') {
        return <div>not found.</div>;
    }

    return (
        <AuthBaseView
            title="Reset Password"
            photoClassName={classes.photoAction}
        >
            {status ? (
                <MessagePanel uri={continueUrl}>
                    Done. Your password was updated.
                </MessagePanel>
            ) : (
                <form
                    className={classes.form}
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                Enter your new password.
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                inputRef={register({
                                    required: 'Input your password',
                                })}
                                error={errors.password && true}
                                helperText={
                                    errors.password && errors.password.message
                                }
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        reset password
                    </Button>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link href={continueUrl} variant="body2">
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            )}
            <Messagebar code={error} onClose={() => setError('')} />
        </AuthBaseView>
    );
};
