import * as firebase from 'firebase/app';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import WebIcon from '@material-ui/icons/Web';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import ListIcon from '@material-ui/icons/List';
import ListAltIcon from '@material-ui/icons/ListAlt';

export const MainListItems = () => {
    const history = useHistory();
    return (
        <div>
            <ListItem button onClick={() => history.push('/')}>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItem>
            {/* <ListItem button onClick={() => history.push('/sites')}>
                <ListItemIcon>
                    <WebIcon />
                </ListItemIcon>
                <ListItemText primary="Sites" />
            </ListItem>
            <ListItem button onClick={() => history.push('/promotions')}>
                <ListItemIcon>
                    <RecordVoiceOverIcon />
                </ListItemIcon>
                <ListItemText primary="Promotions" />
            </ListItem>
            <ListItem button onClick={() => history.push('/reports')}>
                <ListItemIcon>
                    <BarChartIcon />
                </ListItemIcon>
                <ListItemText primary="Reports" />
            </ListItem> */}
        </div>
    );
};

export const SiteMenu = () => {
    const history = useHistory();
    return (
        <div>
            <ListSubheader inset>Site menu</ListSubheader>
            <ListItem button onClick={() => history.push('/sites')}>
                <ListItemIcon>
                    <WebIcon />
                </ListItemIcon>
                <ListItemText primary="Your sites" />
            </ListItem>
            <ListItem button onClick={() => history.push('/reports/sites')}>
                <ListItemIcon>
                    <TrendingUpIcon />
                </ListItemIcon>
                <ListItemText primary="Reports" />
            </ListItem>
            <ListItem button onClick={() => history.push('/logs/sites')}>
                <ListItemIcon>
                    <ListIcon />
                </ListItemIcon>
                <ListItemText primary="Logs" />
            </ListItem>
        </div>
    );
};

export const PromotionMenu = () => {
    const history = useHistory();
    return (
        <div>
            <ListSubheader inset>Promotion menu</ListSubheader>
            <ListItem button onClick={() => history.push('/promotions')}>
                <ListItemIcon>
                    <RecordVoiceOverIcon />
                </ListItemIcon>
                <ListItemText primary="Your promotions" />
            </ListItem>
            <ListItem
                button
                onClick={() => history.push('/reports/promotions')}
            >
                <ListItemIcon>
                    <BarChartIcon />
                </ListItemIcon>
                <ListItemText primary="Reports" />
            </ListItem>
            <ListItem button onClick={() => history.push('/logs/promotions')}>
                <ListItemIcon>
                    <ListAltIcon />
                </ListItemIcon>
                <ListItemText primary="Logs" />
            </ListItem>
        </div>
    );
};

export const UserListItems = () => {
    const history = useHistory();
    return (
        <div>
            <ListSubheader inset>Account menu</ListSubheader>
            <ListItem button onClick={() => firebase.auth().signOut()}>
                <ListItemIcon>
                    <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary="SignOut" />
            </ListItem>
        </div>
    );
};

export const secondaryListItems = (
    <div>
        <ListSubheader inset>Site performance</ListSubheader>
        <ListItem button>
            <ListItemIcon>
                <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Today" />
        </ListItem>
        <ListItem button>
            <ListItemIcon>
                <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Current week" />
        </ListItem>
        <ListItem button>
            <ListItemIcon>
                <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Current month" />
        </ListItem>
    </div>
);
